import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import MyHelmet from "../components/MyHelmet"
import { Container, Section } from "./Markup";
import './all.scss'

const withWrapper = (children) => (
  <Section>
    <Container>
      {children}
    </Container>
  </Section>
);

const Layout = ({children, footer, header, fullWidth = false}) => {
  const HeaderComponent = typeof (header) === 'object' ? header : <Navbar/>;
  const FooterComponent = typeof (footer) === 'object' ? footer : <Footer/>;
  const Content = fullWidth ? children : children && withWrapper(children);

  return (
    <div>
      <MyHelmet/>
      {HeaderComponent}
      {Content}
      {FooterComponent}
    </div>
  )
};

export default Layout
