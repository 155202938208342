import React from 'react';

export const Container = ({className = '', fluid = false, children}) => (
  <div className={`container ${fluid ? 'is-fluid' : ''} ${className}`}>
    {children}
  </div>
);

export const Section = ({className = '', children}) => (
  <section className={`section ${className}`}>
    {children}
  </section>
);

export const Button = ({className = '', children, ...buttonProps}) => (
  <button className={`button ${className}`} {...buttonProps}>
    {children}
  </button>
);

export const Input = ({className = '', children, ...inputProps}) => {
  const baseProps = {type: 'text', placeholder: ''};
  return (
    <input className={`input ${className}`} {...baseProps} {...inputProps} >
      {children}
    </input>
  )
};

export  default null