import React, { useState } from 'react'
import { Link } from 'gatsby'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faMedium } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useStaticQuery, graphql } from 'gatsby'
import SocialLink from "../components/SocialLink"
import { Container } from "./Markup";
import { Location } from "@reach/router"


export default () => {
  const [active, setActive] = useState(false);

  const socialData = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          twitter_url
          linkedin_url
          instagram_url
          medium_url
        }
      }
    }
  `);

  return (
    <Location>
      {({ location: {pathname} }) => (
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <Container>
            <div className="navbar-brand">
              <Link to="/" activeClassName="is-active" className="navbar-item">Amy Strike</Link>
              <a role="button" className={"navbar-burger burger" + (active ? "is-active" : "")} aria-label="menu" aria-expanded="false" onClick={() => setActive(!active)}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div className={"navbar-menu" + (active ? "is-active" : "")}>
              {pathname !== '/' && (
                <div className="navbar-start">
                  <Link to="/playable" activeClassName="is-active" className="navbar-item">Play Now</Link>
                  <Link to="/events" activeClassName="is-active" className="navbar-item">Live Events</Link>
                  <Link to="/art" activeClassName="is-active" className="navbar-item">Art</Link>
                  <Link to="/bio" activeClassName="is-active" className="navbar-item">Bio</Link>
                  <Link to="/contact" activeClassName="is-active" className="navbar-item">Contact</Link>
                </div>
              )}
              <div className="navbar-end">
                <SocialLink icon={faTwitter} title="Twitter" href={socialData.markdownRemark.frontmatter.twitter_url}/>
                <SocialLink icon={faInstagram} title="Instagram" href={socialData.markdownRemark.frontmatter.instagram_url}/>
                <SocialLink icon={faLinkedin} title="LinkedIn" href={socialData.markdownRemark.frontmatter.linkedin_url}/>
                <SocialLink icon={faMedium} title="Medium" href={socialData.markdownRemark.frontmatter.medium_url}/>
              </div>
            </div>
          </Container>
        </nav>
      )}
    </Location>
  )
}
